import React, { useState } from "react";
import { auth } from "./firebase";
import Login from "./Login";
import Dashboard from "./Dashboard";
import { signInWithEmailAndPassword } from "firebase/auth";

function App() {
  const [user, setUser] = useState(null);

  const handleLogin = async (email, password) => {
    console.log("handleLogin called with:", email, password); // 追加
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      setUser(userCredential.user);
      console.log("Logged in user:", userCredential.user); // 追加
    } catch (error) {
      console.error("Error logging in: ", error);
    }
  };

  return (
    <div className="App">
      {user ? (
        <Dashboard user={user} />
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </div>
  );
}

export default App;
